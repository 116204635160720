import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Alert } from "react-bootstrap";

function Notification({ message, onClose, variant }) {
  useEffect(() => {
    const timer = setTimeout(onClose, 2000); // Automatically close after 3 seconds
    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className="alert">
      <Alert variant={variant} onClose={onClose} className="alert-padding">
        {message}
      </Alert>
    </div>
  );
}

Notification.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.string, // "success", "danger", etc.
};

Notification.defaultProps = {
  variant: "success", // default variant is success
};

export default Notification;
