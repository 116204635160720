import React, { useContext, useState } from "react";
import ReactGA from "react-ga4";
import { FaCopy, FaExternalLinkAlt } from "react-icons/fa";
import { useAuth } from "../../AuthContext";
import { AppContext } from "../../Context";
import { useLoginModal } from "../../LoginModalContext";
import { requestShortUrl } from "../../Services/shortUrl";
import Notification from "../Notification";
import Submit from "../Submit";
import { alphanumericRegex } from "../../Constants/regexConstant";

function UrlForm({ onQrCodeGenerated }) {
  const [values, setValues] = useState("");
  const [shortId, setShortId] = useState("");
  const [shortUrl, setShortUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [shortIdError, setShortIdError] = useState("");
  const [notification, setNotification] = useState({
    message: "",
    variant: "",
  });
  const { qrCode } = useContext(AppContext);
  const { token } = useAuth();
  const { openLoginModal } = useLoginModal();

  const handleChange = (event) => {
    setValues(event.target.value);
    setErrorMessage(""); // Clear error message on input change
  };

  const handleShortIdChange = (event) => {
    const input = event.target.value;

    if (!alphanumericRegex.test(input)) {
      setShortIdError("Short ID must be alphanumeric.");
    } else if (input.length === 0) {
      setShortIdError("");
    } else if (input.length !== 8) {
      setShortIdError("Short ID must be exactly 8 characters.");
    } else {
      setShortIdError(""); // Clear any previous error if valid
    }

    setShortId(input);
    setErrorMessage(""); // Clear error message on input change
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    ReactGA.event({
      category: "Form",
      action: "Submitted Generate form",
      label: "Link Generate form",
    });
    qrCode.update({
      data: shortUrl || values,
    });
    onQrCodeGenerated(); // Notify parent that QR code is ready
  };

  const handleShortUrl = async (event) => {
    event.preventDefault();

    if (!token) {
      openLoginModal();
      return;
    }

    const longURL = values.trim();

    if (longURL === "") {
      setErrorMessage("Please enter a URL.");
      return;
    }

    try {
      const data = { url: longURL };
      if (shortId) {
        data.shortId = shortId;
      }
      const response = await requestShortUrl(data);
      const newShortUrl = response.data.redirectURL;
      setShortUrl(newShortUrl);
      qrCode.update({
        data: newShortUrl,
      });
    } catch (error) {
      setErrorMessage(
        error?.response?.data?.message ||
          "Failed to shorten the URL. Please try again later."
      );
    }
    onQrCodeGenerated(); // Notify parent that QR code is ready
  };

  const handleCopy = () => {
    if (shortUrl) {
      navigator.clipboard
        .writeText(shortUrl)
        .then(() => {
          setNotification({
            message: "URL copied to clipboard",
            variant: "success",
          });
        })
        .catch((err) => {
          setNotification({ message: "Failed to copy URL", variant: "danger" });
        });
    }
  };

  const handleOpenLink = () => {
    if (shortUrl) {
      window.open(shortUrl, "_blank");
    }
  };

  const handleCloseNotification = () => {
    setNotification({ message: "", variant: "" });
  };

  return (
    <div>
      {notification.message && (
        <Notification
          message={notification.message}
          onClose={handleCloseNotification}
          variant={notification.variant}
        />
      )}
      <form className="qrForm-url" onSubmit={handleSubmit}>
        <div className="form-floating mb-2">
          <input
            id="url"
            className="form-control"
            type="url"
            name="url"
            value={values}
            onChange={handleChange}
            placeholder="Enter link (URL) here"
            required
          />
          <label htmlFor="url">Enter link (URL) here</label>
          {errorMessage && <div className="text-danger">{errorMessage}</div>}
        </div>
        {token && (
          <>
            <div className="d-flex align-items-center">
              <h6 className="mb-0">Personalize Your Short ID (Optional)</h6>
            </div>
            <div className="d-flex align-items-center mb-3">
              <h6 className="domain me-2 px-3 bg-light border rounded">
                https://r.lnkpro.in/
              </h6>
              <div className="short-id-input form-floating mt-3">
                <input
                  id="shortId"
                  className="form-control"
                  type="text"
                  name="shortId"
                  value={shortId}
                  onChange={handleShortIdChange}
                  placeholder="Enter short id here"
                />
                <label htmlFor="shortId">Enter Short ID here</label>
                {shortIdError && (
                  <div className="text-danger short-id-error">
                    {shortIdError}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <div>
          <span className="url-submit-button">
            <Submit />
          </span>
          <span>
            <button
              className="btn btn-primary"
              type="button"
              onClick={handleShortUrl}
              disabled={shortIdError}
            >
              Shorten URL and Generate QR Code
            </button>
          </span>
        </div>
        {shortUrl && (
          <div className="url-box-style my-3 p-3 bg-light border rounded d-flex align-items-center">
            <p className="flex-grow-1 lnkpro-url-font">
              LnkPro URL:
              <a
                href={shortUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none ms-2 url-achor-word-break"
              >
                {shortUrl}
              </a>
            </p>
            <FaCopy
              className="url-icons mx-3"
              onClick={handleCopy}
              title="Copy URL"
            />
            <FaExternalLinkAlt
              className="url-icons"
              onClick={handleOpenLink}
              title="Open URL"
            />
          </div>
        )}
      </form>
    </div>
  );
}

export default UrlForm;
