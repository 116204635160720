import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useAuth } from "../AuthContext";
import { requestChangePassword } from "../Services/changePassword";
import AlertComponent from "./Alert";

const ChangePassword = (props) => {
  // logout from logoutContext
  const { logout } = useAuth();
  // State for showing/hiding password on eye icon
  const [showOldPassword, setShowOldPassword] = useState(false);
  // State for showing/hiding password on eye icon
  const [showNewPassword, setShowNewPassword] = useState(false);
  // State for showing/hiding password on eye icon
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // State for storing password value
  const [oldPassword, setOldPassword] = useState("");
  // State for storing password value
  const [newPassword, setNewPassword] = useState("");
  // State for storing password value
  const [confirmPassword, setConfirmPassword] = useState("");
  // State for storing message
  const [errorMessage, setErrorMessage] = useState("");

  // function for handling password visibility
  const toggleOldPasswordVisibility = () =>
    setShowOldPassword(!showOldPassword);
  // function for handling password visibility
  const toggleNewPasswordVisibility = () =>
    setShowNewPassword(!showNewPassword);
  // function for handling password visibility
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handleFormSubmit = async (e) => {
    // to prevent the page from reloading when the form is submitted, to handle the login asynchronously.
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setErrorMessage("New password and confirm password do not match.");
      return;
    }
    try {
      const finalData = {
        oldPassword: oldPassword,
        newPassword: newPassword,
      };
      const response = await requestChangePassword(finalData);
      let message =
        response?.message ||
        response?.data?.data ||
        "Password changed successfully";
      // function call for closing change password modal from headers
      props?.handleClose();
      // state call for storing notification message from headers
      props?.setNotification(message);
      // state call for storing notification message variant from headers
      props?.setNotificationVariant("success");
      // Called logout after password change
      logout();
      // state call for hiding the login dropdown/user dropdown
      props?.setShowDropdown(false);
      // state call for making login false as to redirect for logging in
      props?.setIsLoggedIn(false);
    } catch (e) {
      let message = e?.response?.data?.message || e?.response?.data?.data;
      setErrorMessage(message);
    }
  };

  return (
    <>
      {errorMessage && (
        <AlertComponent
          variant="danger"
          message={errorMessage}
          onClose={() => setErrorMessage("")}
        />
      )}
      <form onSubmit={handleFormSubmit} autoComplete="off">
        <div className="mb-3 position-relative">
          <label htmlFor="oldPassword" className="form-label">
            Current Password
          </label>
          <input
            type={showOldPassword ? "text" : "password"}
            className="form-control"
            name="oldPassword"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            required
          />
          <span
            onClick={toggleOldPasswordVisibility}
            className="password-eye-icon"
          >
            {showOldPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>
        <div className="mb-3 position-relative">
          <label htmlFor="newPassword" className="form-label">
            New Password
          </label>
          <input
            type={showNewPassword ? "text" : "password"}
            className="form-control"
            name="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <span
            onClick={toggleNewPasswordVisibility}
            className="password-eye-icon"
          >
            {showNewPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>
        <div className="mb-3 position-relative">
          <label htmlFor="confirmPassword" className="form-label">
            Confirm Password
          </label>
          <input
            type={showConfirmPassword ? "text" : "password"}
            className="form-control"
            name="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <span
            onClick={toggleConfirmPasswordVisibility}
            className="password-eye-icon"
          >
            {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>
        <Button className="submit-button-change-password" variant="primary" type="submit">
          Submit
        </Button>
      </form>
    </>
  );
};

export default ChangePassword;
