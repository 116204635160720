export const initialOptions = {
  size: 1000,
  removeBrand: false,
  imageMargin: 20,
  mainShape: "dots",
  shapeColor: "#0084C7", // Color matching the blue in the logo
  squareShape: "extra-rounded",
  squareColor: "#FF7C1F", // Color matching the orange in the logo
  cornersDotShape: "extra-rounded",
  cornersDotColor: "#0084C7", // Color matching the blue in the logo
  image: "/img/jetatechlogo.png",
};
