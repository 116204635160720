// src/OffcanvasContext.js
import React, { createContext, useReducer, useContext } from "react";

const OffcanvasContext = createContext();

const initialState = {
  offcanvas: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "toggle":
      return { ...state, offcanvas: !state.offcanvas };
    case "close":
      return { ...state, offcanvas: false };
    default:
      return state;
  }
};

const OffcanvasProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <OffcanvasContext.Provider value={{ state, dispatch }}>
      {children}
    </OffcanvasContext.Provider>
  );
};

// Custom hook to use the Offcanvas context
const useOffcanvas = () => {
  return useContext(OffcanvasContext);
};

export { OffcanvasProvider, useOffcanvas };
