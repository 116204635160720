import React, { useEffect } from "react";

const AdComponent = () => {
  useEffect(() => {
    // Add the Google AdSense script to the document head
    const script = document.createElement("script");
    script.async = true;
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    script.onload = () => {    
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
    document.head.appendChild(script);
  }, []);

  return (
    <div className="ad-container">
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3531446167436482"
        crossOrigin="anonymous"
      ></script>
      {/* <!-- Square Dabba --> */}
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-3531446167436482"
        data-ad-slot="9392157989"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
      <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
    </div>
  );
};
export default AdComponent;
