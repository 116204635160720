import React, { useEffect, useState } from "react";
import { requestUpdateUser } from "../Services/signup";

const EmailVerification = () => {
  // state for showing verification message
  const [verificationMessage, setVerificationMessage] = useState(
    "Verifying your email..."
  );
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    const userId = queryParams.get("abc");
    sessionStorage.setItem("token", token);
    if (!userId) {
      setVerificationMessage("Invalid or missing user ID.");
      setIsError(true);
      return;
    }

    const verifyEmail = async () => {
      try {
        const response = await requestUpdateUser({ _id: userId });
        // Check if the status code is 200 for success
        if (response?.success === true) {
          setVerificationMessage(
            response?.message || "Verification Successful!"
          );
          setIsError(false);
        } else {
          setVerificationMessage(
            response?.message || "Verification failed. Please try again."
          );
          setIsError(true);
        }
      } catch (error) {
        setVerificationMessage(
          error?.response?.data?.message ||
            "Verification failed. Please try again."
        );
        setIsError(true);
      }
    };

    verifyEmail();
  }, []);

  return (
    <>
      <h2
        className={`verification-message ${isError ? "error" : "success"}`} // Conditionally applying CSS classes
      >
        {verificationMessage}
      </h2>
    </>
  );
};

export default EmailVerification;
