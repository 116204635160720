// Offcanvas.js
import React, { useContext, useEffect, useRef } from "react";
import { initialOptions } from "../Constants/initialOptions";
import { AppContext } from "../Context";
import { useOffcanvas } from "../CustomizationContext";

const defaultBrand = "";

const Offcanvas = ({ options, handleOptions, setOptions, uploadError }) => {
  const { qrCode } = useContext(AppContext);
  const { state, dispatch } = useOffcanvas();

  const uploadRef = useRef(null);

  const handleSave = (event) => {
    const button = event.currentTarget;
    button.innerText = "Saving..";
    setTimeout(() => {
      button.innerText = "Save Style";
    }, 1000);
    localStorage.setItem("qr-code", JSON.stringify(options));
  };

  const handleSavedValues = () => {
    const savedValues = localStorage.getItem("qr-code");
    if (savedValues) {
      setOptions(JSON.parse(savedValues));
    }
  };

  const handleResetOptions = () => {
    if (uploadRef.current) {
      uploadRef.current.value = "";
    }
    setOptions(initialOptions);
  };

  const handleResetImage = () => {
    if (uploadRef.current) {
      uploadRef.current.value = "";
      uploadError.current = "";
      setOptions((prev) => ({
        ...prev,
        image: defaultBrand,
      }));
    }
  };

  useEffect(() => {
    const savedValues = localStorage.getItem("qr-code");
    if (savedValues) {
      setOptions(JSON.parse(savedValues));
    }
  }, []);

  useEffect(() => {
    const image = options.removeBrand ? "" : options.image;
    qrCode.update({
      width: options.size,
      height: options.size,
      image,
      dotsOptions: {
        type: options.mainShape,
        color: options.shapeColor,
      },
      cornersSquareOptions: {
        type: options.squareShape,
        color: options.squareColor,
      },
      cornersDotOptions: {
        type: options.cornersDotShape,
        color: options.cornersDotColor,
      },
      imageOptions: {
        margin: options.imageMargin,
      },
    });
  }, [options, qrCode]);

  return (
    <div
      className={`offcanvas offcanvas-start ${state.offcanvas ? "show" : ""}`}
      style={{ visibility: state.offcanvas ? "visible" : "hidden" }}
      tabIndex={-1}
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title">Customization</h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => dispatch({ type: "close" })}
        />
      </div>
      <div className="offcanvas-body">
        <div className="main-shape">
          <div className="row">
            <label htmlFor="mainShape" className="form-label">
              Main Shape
            </label>
            <div className="col-8">
              <select
                id="mainShape"
                className="form-select"
                name="mainShape"
                value={options.mainShape}
                onChange={handleOptions}
              >
                <option value="square">Square</option>
                <option value="dots">Dots</option>
                <option value="rounded">Rounded</option>
                <option value="extra-rounded">Extra Rounded</option>
              </select>
            </div>
            <div className="col-4">
              <input
                id="shapeColor"
                className="form-control form-control-color"
                type="color"
                name="shapeColor"
                value={options.shapeColor}
                onChange={handleOptions}
              />
            </div>
          </div>
        </div>
        <div className="square-shape">
          <div className="row pt-3">
            <label htmlFor="squareShape" className="form-label">
              Square In Corners
            </label>
            <div className="col-8">
              <select
                id="squareShape"
                className="form-select"
                name="squareShape"
                value={options.squareShape}
                onChange={handleOptions}
              >
                <option value="square">Square</option>
                <option value="extra-rounded">Extra Rounded</option>
                <option value="rounded">Rounded</option>
              </select>
            </div>
            <div className="col-4">
              <input
                id="squareColor"
                className="form-control form-control-color"
                type="color"
                name="squareColor"
                value={options.squareColor}
                onChange={handleOptions}
              />
            </div>
          </div>
        </div>
        <div className="corners-dot-shape">
          <div className="row pt-3">
            <label htmlFor="cornersDotShape" className="form-label">
              Dot In Corners
            </label>
            <div className="col-8">
              <select
                id="cornersDotShape"
                className="form-select"
                name="cornersDotShape"
                value={options.cornersDotShape}
                onChange={handleOptions}
              >
                <option value="dot">Dot</option>
                <option value="square">Square</option>
                <option value="extra-rounded">Extra Rounded</option>
                <option value="rounded">Rounded</option>
              </select>
            </div>
            <div className="col-4">
              <input
                id="cornersDotColor"
                className="form-control form-control-color"
                type="color"
                name="cornersDotColor"
                value={options.cornersDotColor}
                onChange={handleOptions}
              />
            </div>
          </div>
        </div>
        <div className="image">
          <div className="row pt-3">
            <label htmlFor="image" className="form-label">
              Image
            </label>
            <div className="col-12">
              <input
                id="image"
                className="form-control"
                type="file"
                name="image"
                accept="image/*"
                ref={uploadRef}
                onChange={handleOptions}
              />
            </div>
            {uploadError.current && (
              <span className="error">{uploadError.current}</span>
            )}
          </div>
          <div className="d-grid d-md-flex justify-content-center py-3">
            <button
              className="btn btn-outline-secondary btn-sm"
              type="button"
              onClick={handleResetImage}
            >
              Reset Image
            </button>
          </div>
        </div>
        <div className="image-margin">
          <div className="row pt-3">
            <label htmlFor="imageMargin" className="form-label">
              Image Margin
            </label>
            <div className="col-12">
              <input
                id="imageMargin"
                className="form-range"
                type="range"
                name="imageMargin"
                min="0"
                max="50"
                step="5"
                value={options.imageMargin}
                onChange={handleOptions}
              />
              <div className="d-flex justify-content-between small">
                <span className="text-muted">Low</span>
                <span className="fw-bold">{options.imageMargin}</span>
                <span className="text-muted">High</span>
              </div>
            </div>
          </div>
        </div>
        <div className="d-grid gap-2 d-md-flex justify-content-center pt-4">
          <button
            className="btn btn-outline-danger me-auto"
            type="button"
            onClick={handleResetOptions}
          >
            Reset
          </button>
          <button
            className="btn btn-primary"
            type="button"
            onClick={handleSave}
          >
            Save Style
          </button>
          <button
            className="btn btn-outline-success"
            type="button"
            onClick={handleSavedValues}
          >
            Apply Saved Style
          </button>
        </div>
      </div>
    </div>
  );
};

export default Offcanvas;
