import React, { useContext, useEffect, useRef, useState } from "react";
import ReactGA from "react-ga4";
import { Route, Routes, useLocation } from "react-router-dom"; // Import routing components
import { useAuth } from "./AuthContext";
import AdComponent from "./Components/AdComponent";
import Download from "./Components/Download";
import EmailVerification from "./Components/EmailVerification";
import Footer from "./Components/Footer";
import ForgotPasswordPage from "./Components/ForgotPasswordPage";
import EmailForm from "./Components/Forms/Email";
import TextForm from "./Components/Forms/Text";
import UrlForm from "./Components/Forms/Url";
import VCardForm from "./Components/Forms/VCard";
import WiFiForm from "./Components/Forms/WiFi";
import Header from "./Components/Header";
import MyAccount from "./Components/MyAccount";
import Tabs from "./Components/Tabs";
import { AppContext } from "./Context";
import { useOffcanvas } from "./CustomizationContext";
import { useLoginModal } from "./LoginModalContext";
import Offcanvas from "./Components/offCanvasDrawer";
import { initialOptions } from "./Constants/initialOptions";

const tabs = [
  { label: "Link", Component: UrlForm },
  { label: "Text", Component: TextForm },
  { label: "E-mail", Component: EmailForm },
  { label: "VCard", Component: VCardForm, requiresAuth: true },
  { label: "WiFi", Component: WiFiForm, requiresAuth: true },
];

const TRACKING_ID = "G-6CXX2SWV93"; // YOUR_OWN_TRACKING_ID

function App() {
  const { canvasRef } = useContext(AppContext);
  const { token } = useAuth();
  const { openLoginModal } = useLoginModal();
  const { dispatch } = useOffcanvas(); // Access offcanvas context

  const [isQrCodeReady, setIsQrCodeReady] = useState(false);
  const [options, setOptions] = useState(initialOptions);

  const uploadError = useRef("");

  const handleOptions = (event) => {
    const { type, name, value, checked, files } = event.target;
    setOptions((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (!files) return;
    const image = files[0];
    uploadError.current = "";

    if (image) {
      // Check supported formats
      if (!image.type.match("image.*")) {
        uploadError.current = "Error: File is not supported.";
        return;
      }
      // Check max size (2M in Bytes)
      if (image.size > 2097152) {
        uploadError.current = "Error: Maximum file size is 2 MB";
        return;
      }
      const fileReader = new FileReader();
      fileReader.onerror = (err) => {
        uploadError.current = `Failed: ${err}`;
      };
      fileReader.onload = () => {
        setOptions((prev) => ({
          ...prev,
          image: fileReader.result,
        }));
      };
      fileReader.readAsDataURL(image);
    }
  };

  const handleQrCodeGenerated = () => {
    setIsQrCodeReady(true);
  };

  const handleOffcanvas = () => dispatch({ type: "toggle" });

  const handleTabSelect = (index) => {
    if (tabs[index].requiresAuth && !token) {
      // Check if token exists
      openLoginModal();
      return false; // Prevent tab change
    }
    return true; // Allow tab change
  };

  const location = useLocation();

  useEffect(() => {
    // Initialize GA4 with the tracking ID
    ReactGA.initialize(TRACKING_ID);
    // Track page views on initial load
    ReactGA.send({
      hitType: "pageview",
      page: location?.pathname + location?.search,
    });
  }, [location]);

  return (
    <div className="d-flex flex-column App">
      <Header currentRoute={location.pathname} />
      <main className="content">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <div className="container">
                  <div className="row justify-content-between g-5 py-5 mt-1">
                    <div className="col-12 col-md-6 col-lg-6">
                      <Tabs
                        className="my-3"
                        tabs={tabs}
                        type="pills"
                        onTabSelect={handleTabSelect}
                        onQrCodeGenerated={handleQrCodeGenerated}
                      />
                      <AdComponent />
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="qr-code-container">
                        <div
                          className="qr-code text-center mx-auto"
                          ref={canvasRef}
                        />
                        <div className="customization">
                          <div className="py-2">
                            <input
                              id="size"
                              className="form-range px-2"
                              type="range"
                              name="size"
                              min="500"
                              max="1500"
                              step="50"
                              value={options.size}
                              onChange={handleOptions}
                            />
                            <div className="d-flex justify-content-between small">
                              <span className="text-muted">Low Quality</span>
                              <span className="fw-bold">{`${options.size} x ${options.size} Px`}</span>
                              <span className="text-muted">High Quality</span>
                            </div>
                          </div>
                          <div className="d-grid py-2">
                            <button
                              className="btn btn-primary py-2"
                              type="button"
                              onClick={handleOffcanvas}
                            >
                              Customize QR Code
                            </button>
                          </div>
                          <div className="d-grid py-2">
                            <div className="form-check form-switch">
                              <input
                                id="removeBrand"
                                className="form-check-input"
                                type="checkbox"
                                name="removeBrand"
                                checked={options.removeBrand}
                                onChange={handleOptions}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="removeBrand"
                              >
                                Remove Brand
                              </label>
                            </div>
                          </div>
                          {isQrCodeReady && <Download />}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
          />
          <Route path="/email-verification" element={<EmailVerification />} />{" "}
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />{" "}
          <Route path="/my-account" element={<MyAccount />} />{" "}
        </Routes>
      </main>
      <Footer />
      <Offcanvas
        options={options}
        setOptions={setOptions}
        handleOptions={handleOptions}
        uploadError={uploadError}
      />
    </div>
  );
}

export default App;
