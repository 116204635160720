import React, { useCallback, useContext, useEffect, useState } from "react";
import { Card, Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { FaDownload, FaPaintBrush } from "react-icons/fa";
import { AppContext } from "../Context";
import { requestShortUrlList } from "../Services/shortUrlListData";
import TableComponent from "./DataTableComponent";
import Kpis from "./Kpis";
import { LNKPRO_URL } from "../Constants/constant";
import { useOffcanvas } from "../CustomizationContext";

const MyAccount = () => {
  const { qrCode } = useContext(AppContext);
  const { dispatch } = useOffcanvas();
  const [modalVisible, setModalVisible] = useState(false);
  const [shortUrlListData, setShortUrlListData] = useState([]);
  const [totalHits, setTotalHits] = useState(0);
  const [loading, setLoading] = useState(true);
  const [totalUrlsLastSixMonths, setTotalUrlsLastSixMonths] = useState(0);

  useEffect(() => {
    const fetchShortUrlList = async () => {
      setLoading(true);
      try {
        const response = await requestShortUrlList();
        const shortUrllist = response?.data?.map((e) => ({
          shortId: e.shortId,
          lnkproUrl: e.redirectURL,
          createdDate: new Date(e.createdDate).toLocaleDateString(),
          hitCount: e.hitCount,
        }));

        setShortUrlListData(shortUrllist);

        const hits = shortUrllist.reduce(
          (sum, url) => sum + (url.hitCount || 0),
          0
        );
        setTotalHits(hits);

        const today = new Date();
        const lastSixMonths = [];
        for (let i = 0; i < 6; i++) {
          const month = new Date(today);
          month.setMonth(today.getMonth() - i);
          const monthName = month.toLocaleString("default", { month: "long" });
          const year = month.getFullYear();

          const count = shortUrllist.filter((url) => {
            const urlDate = new Date(url.createdDate);
            return (
              urlDate.getMonth() === month.getMonth() &&
              urlDate.getFullYear() === year
            );
          }).length;

          lastSixMonths.push({ month: monthName, count });
        }

        const totalUrlsLastSixMonthsAgo = lastSixMonths
          .reverse()
          .map((item) => item.count);
        setTotalUrlsLastSixMonths(totalUrlsLastSixMonthsAgo);
      } catch (error) {
        console.error("Error fetching short URL list:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchShortUrlList();
  }, []);

  const columns = [
    { header: "Short Id", accessor: "shortId" },
    {
      header: "Long Url",
      accessor: "lnkproUrl",
      render: (row) => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{row.lnkproUrl}</Tooltip>}
        >
          <span>
            {row.lnkproUrl.length > 50
              ? `${row.lnkproUrl.substring(0, 50)}...`
              : row.lnkproUrl}
          </span>
        </OverlayTrigger>
      ),
    },
    { header: "Created Date", accessor: "createdDate" },
    { header: "No. Of Clicks", accessor: "hitCount" },
    {
      header: "Action",
      render: (row) => (
        <div className="d-flex mouse-pointer">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Download QR Code</Tooltip>}
          >
            <span>
              <FaDownload onClick={() => handleDownload("jpg", row.shortId)} />
            </span>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Customize QR Code</Tooltip>}
          >
            <span className="ms-3">
              <FaPaintBrush onClick={handleOpenOffcanvas} />
            </span>
          </OverlayTrigger>
        </div>
      ),
    },
  ];

  const handleClose = () => {
    setModalVisible(false);
  };

  const handleDownload = useCallback(
    async (ext = "jpg", shortId) => {
      try {
        // Update the QR code with the specific URL for the selected shortId
        qrCode.update({
          data: LNKPRO_URL + shortId,
        });

        // Trigger download with the updated QR code
        qrCode.download({ extension: ext });
      } catch (error) {
        console.error("Error downloading QR code:", error);
      }
    },
    [qrCode]
  );

  const handleOpenOffcanvas = () => {
    dispatch({ type: "toggle" });
  };

  return (
    <>
      <div className="container">
        {loading ? (
          <div className="d-flex justify-content-center align-items-center min-vh-100">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <div className="row justify-content-between g-2 py-3 mt-3">
            <div className="d-flex flex-wrap align-items-center mt-5">
              <div className="mb-4 col-12 col-md-5 g-2">
                <h4>Short Url Based on Month</h4>
                <Kpis totalUrlsLastSixMonths={totalUrlsLastSixMonths} />
              </div>

              {/* Displaying Total Number of URLs and Hits beside Kpis */}
              <div className="row col-12 col-md-6">
                {/* First card for total short URLs */}
                <div className="col-6">
                  <Card className="h-100">
                    <Card.Body>
                      <Card.Title>Total Short URLs</Card.Title>
                      <h1 className="text-center mt-5">
                        {shortUrlListData.length}
                      </h1>
                    </Card.Body>
                  </Card>
                </div>

                {/* Second card for total number of clicks */}
                <div className="col-6">
                  <Card className="h-100">
                    <Card.Body>
                      <Card.Title>Total No. Of Clicks</Card.Title>
                      <h1 className="text-center mt-5">{totalHits}</h1>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>

            {/* Table Component */}
            <h4>Short Url List Data</h4>
            <div className="table-responsive">
              <TableComponent columns={columns} data={shortUrlListData} />
            </div>
          </div>
        )}
      </div>

      <Modal size="lg" show={modalVisible} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>View Statistics</Modal.Title>
        </Modal.Header>
        <Modal.Body>Hello</Modal.Body>
      </Modal>
    </>
  );
};

export default MyAccount;
