import React from "react";
import Chart from "react-apexcharts";

const Kpis = ({ totalUrlsLastSixMonths }) => {
  const getLastSixMonths = () => {
    const today = new Date();
    const lastSixMonths = [];
    for (let i = 0; i < 6; i++) {
      const date = new Date(today);
      date.setMonth(today.getMonth() - i);
      lastSixMonths.push(date.toLocaleString("default", { month: "long" }));
    }
    return lastSixMonths.reverse();
  };

  const options = {
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: getLastSixMonths(),
    },
  };

  const series = [
    {
      name: "Total Short URLs",
      data: totalUrlsLastSixMonths, // Use the data from the parent
    },
  ];

  return (
    <div>
      <Chart options={options} series={series} type="bar" width="450" />
    </div>
  );
};

export default Kpis;
