// src/termsConditions.js
import React from 'react';
import { Modal } from 'react-bootstrap';

const TermsConditionsModal = ({ isOpen, handleCloseTerms }) => {
    return (
        <Modal dialogClassName="modal-xl" show={isOpen} onHide={handleCloseTerms}>
            <Modal.Header closeButton>
                <Modal.Title>Terms and Conditions</Modal.Title>
            </Modal.Header>
            <Modal.Body className='terms-modal'>
                <html>
                    <body>
                        <p>
                            TERMS OF USE<br /><br />
                            PLEASE READ THESE TERMS AND CONDITIONS<br /><br />
                            <strong>*Standard Terms and Conditions for Customer's use of the Site for QR Code Generation and URL Shortening*</strong><br /><br />
                            These Terms and Conditions ("Terms") constitute a legal agreement between JetaTech
                            InfoServices Private Limited, a company duly incorporated under the laws of
                            India, with its principal place of business at Pune, Maharashtra] ("Provider"
                            or "we," "us," or "our"), and the entity or individual agreeing to these Terms
                            ("Customer" or "you" or "your"). These Terms govern Customer's use of the
                            software-as-a-service platform for QR Code Generation and URL Shortening provided by Provider
                            ("Service").<br /><br />
                            &nbsp;&nbsp;&nbsp;&nbsp;1. Acceptance of Terms<br />
                            By accessing or using the Service, Customer agrees to be bound by these Terms. If Customer does not agree to
                            these Terms, they should refrain from accessing or using the Service.<br /><br />
                            &nbsp;&nbsp;&nbsp;&nbsp;2. Provision of Service<br />
                            2.1. Subject to Customer's compliance with these Terms
                            and payment of any applicable fees, Provider will provide Customer with access
                            to the Service.<br />
                            2.2. Provider reserves the right to modify, suspend, or
                            terminate the Service or any part thereof, at any time with or without notice.<br /><br />
                            &nbsp;&nbsp;&nbsp;&nbsp;3. Use of Service<br />
                            3.1. Customer shall use the Service solely for its intended
                            purposes and in accordance with any instructions or guidelines provided by
                            Provider.<br />
                            3.2. Customer shall not engage in any activity that interferes with
                            or disrupts the Service or its associated servers and networks.<br />
                            3.3. Customer
                            is responsible for maintaining the security of their account credentials and
                            for any activity that occurs under their account.<br /><br />
                            &nbsp;&nbsp;&nbsp;&nbsp;4. Fees and Payment<br />
                            4.1.
                            Customer shall pay all fees associated with their use of the Service as set
                            forth in the applicable pricing plan.<br />
                            4.2. All fees are non-refundable once
                            paid.<br />
                            4.3. Provider reserves the right to change the fees and billing methods
                            upon prior notice to Customer.<br /><br />
                            &nbsp;&nbsp;&nbsp;&nbsp;5. Intellectual Property<br />
                            5.1. Provider retains
                            all right, title, and interest in and to the Service, including all
                            intellectual property rights.<br />
                            5.2. Customer retains all right, title, and
                            interest in and to any data or content provided by Customer through the
                            Service.<br /><br />
                            &nbsp;&nbsp;&nbsp;&nbsp;6. Confidentiality<br />
                            6.1. Each party may have access to certain
                            non-public or proprietary information of the other party ("Confidential
                            Information") in connection with these Terms.<br />
                            6.2. The receiving party agrees
                            to maintain the confidentiality of the disclosing party's Confidential
                            Information and not to disclose or use such Confidential Information except as
                            necessary to fulfill its obligations under these Terms.<br /><br />
                            &nbsp;&nbsp;&nbsp;&nbsp;7. Warranty and
                            Disclaimer<br />
                            7.1. Provider warrants that it will provide the Service in a manner
                            consistent with general industry standards.<br />
                            7.2. EXCEPT AS EXPRESSLY PROVIDED
                            IN THESE TERMS, THE SERVICE IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT ANY
                            WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED.<br /><br />
                            &nbsp;&nbsp;&nbsp;&nbsp;8. Limitation of Liability<br />
                            8.1. IN NO EVENT SHALL PROVIDER BE LIABLE TO CUSTOMER OR ANY THIRD PARTY FOR
                            ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING
                            OUT OF OR IN CONNECTION WITH THE SERVICE.<br />
                            8.2. PROVIDER'S TOTAL LIABILITY
                            ARISING OUT OF OR IN CONNECTION WITH THESE TERMS SHALL NOT EXCEED THE TOTAL
                            FEES PAID BY CUSTOMER TO PROVIDER IN THE SIX (6) MONTHS PRECEDING THE EVENT
                            GIVING RISE TO THE LIABILITY.<br /><br />
                            &nbsp;&nbsp;&nbsp;&nbsp;9. Termination<br />
                            9.1. Either party may terminate
                            these Terms upon written notice to the other party if the other party
                            materially breaches these Terms and fails to cure such breach within thirty
                            (30) days of receiving notice of the breach.<br />
                            9.2. Upon termination of these
                            Terms, Customer's access to the Service will be terminated, and Provider may
                            delete any data associated with Customer's account.<br /><br />
                            &nbsp;&nbsp;&nbsp;&nbsp;10. Miscellaneous<br />
                            10.1.
                            These Terms constitute the entire agreement between the parties with respect
                            to the subject matter hereof and supersede all prior and contemporaneous
                            agreements and understandings.<br />
                            10.2. These Terms shall be governed by and
                            construed in accordance with the laws of [Jurisdiction] without regard to its
                            conflict of law provisions.<br />
                            10.3. Any disputes arising out of or in connection
                            with these Terms shall be resolved by binding arbitration in accordance with
                            the rules of [Arbitration Institution].<br />
                            10.4. These Terms may be amended or
                            modified only by a written agreement signed by both parties.<br /><br />
                            By accepting
                            these Terms, Customer acknowledges that they have read, understood, and agree
                            to be bound by all of the terms and conditions set forth herein.
                        </p>
                    </body>
                </html>
            </Modal.Body>
        </Modal>
    );
};

export default TermsConditionsModal;
